export default class Footer {
    constructor(node) {
        this.node = node;
        this.node.addEventListener('focusin', this.handleFocusIn.bind(this));
        this.setupResizeObserver();
    }

    handleFocusIn() {
        // Scroll to bottom of page when focus enters the footer, unless the
        // footer is taller than the viewport. This is designed to handle the
        // fact that tabbing into the footer when it is stuck to the bottom of
        // the viewport will not scroll it into view, as its sticky position
        // (obscured by other elements) causes the browser to think it is
        // already in view. If the footer is taller than the viewport, it will
        // not be given sticky position (see note in SCSS file), so normal
        // behaviour is fine.
        const { height } = this.node.getBoundingClientRect();
        const { clientHeight } = document.documentElement;
        const { scrollHeight } = document.body;
        if (height < clientHeight) {
            window.scrollTo({
                left: 0,
                top: scrollHeight,
                behavior: 'smooth',
            });
        }
    }

    handleResize() {
        // Safari does not correctly apply sticky position fallback behaviour
        // when the sticky element is taller than the viewport
        // (https://css-tricks.com/the-slideout-footer/#comment-1754877)
        // so detect that condition and apply a modifier class
        const { height } = this.node.getBoundingClientRect();
        const { clientHeight } = document.documentElement;
        this.node.classList.toggle('is-taller-than-viewport', height > clientHeight);
    }

    setupResizeObserver() {
        if ('ResizeObserver' in window) {
            new window.ResizeObserver(() => this.handleResize()).observe(this.node);
        }
    }
}
